import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/PortalButton";
import PortalLayout from "./PortalLayout";
import { usePortal } from "../context/PortalContext";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const RejectionConfirmed = () => {
	return (
		<PortalLayout>
			<div className="h-full flex flex-col justify-between">
				<div className="mt-8">
					<div className="flex flex-col mb-6">
						<CheckCircleIcon
							className={`size-14 text-green-600 mb-4`}
						/>
					</div>
					<h1 className="text-2xl font-bold">
						Staat genoteerd! Bedankt voor het doorgeven.
					</h1>
					<p className="text-slate-600 pt-4 text-lg">
						We sturen u een bericht zodra er weer een nieuwe plek
						vrijkomt
					</p>
				</div>
			</div>
		</PortalLayout>
	);
};

export default RejectionConfirmed;
