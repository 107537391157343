import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ClerkProvider } from "@clerk/clerk-react";
import { TaskProvider } from "./tasks/context/TaskContext";
import { nlNL } from "@clerk/localizations";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import ErrorBoundary from "./shared/components/util/ErrorBoundary";
import "react-toastify/dist/ReactToastify.css";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));

const hiddenRoutes = ["/c/", "/praktijk/"];
const shouldInitialize = !hiddenRoutes.some((route) =>
	window.location.pathname.startsWith(route),
);

root.render(
	<React.StrictMode>
		<IntercomProvider
			appId="uxltrzl0"
			autoBoot
			shouldInitialize={shouldInitialize}
		>
			<TaskProvider>
				<ClerkProvider publishableKey={clerkPubKey} localization={nlNL}>
					{/* <ErrorBoundary> */}
					<App />
					{/* </ErrorBoundary> */}
					<ToastContainer />
				</ClerkProvider>
			</TaskProvider>
		</IntercomProvider>
	</React.StrictMode>,
);
