import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Button from "../components/PortalButton";
import {
	CalendarDaysIcon,
	ClockIcon,
	UserIcon,
	CheckIcon,
	XMarkIcon,
} from "@heroicons/react/16/solid";
import FormattedDate from "../../shared/components/util/FormattedDate";
import useCustomConfirm from "../../shared/hooks/useCustomConfirm";
import Loading from "../../shared/pages/Loading";
import PortalLayout from "./PortalLayout";
import { usePortal } from "../context/PortalContext";

const ClaimSlotPage = () => {
	const [patient, setPatient] = useState();
	const [slot, setSlot] = useState();
	const [practice, setPractice] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const [confirm, ConfirmModal] = useCustomConfirm();

	const { slotId, patientId } = useParams();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const fromSMS = searchParams.get("channel") === "sms";

	const navigate = useNavigate();
	const portal = usePortal();

	const claimSlot = async () => {
		const result = await confirm({
			title: "Weet u zeker dat u er bent?",
			description:
				"Als u op ja klikt, wordt de plek in de agenda definitief voor u gereserveerd.",
			cta: "Ja, ik ben er",
		});
		if (result) {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/slot/${slotId}/claim`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ patientId }),
				},
			);

			if (response.ok) {
				navigate(`/c/bevestiging/${slotId}?pt=${patientId}`);
			} else {
				navigate(`/c/claimed`);
			}
		}
	};

	const rejectSlot = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/public/slot/${slotId}/reject`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ patientId }),
			},
		);

		if (response.ok) {
			navigate(`/c/kan-niet/`);
		}
	};

	useEffect(() => {
		const fetchSlot = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/slot/${slotId}`,
			);
			const responseData = await response.json();
			if (
				responseData.slot.status === "claimed" &&
				responseData.slot.claimedBy !== patientId
			) {
				navigate(`/c/claimed`);
			} else {
				setSlot(responseData.slot);
				setPractice(responseData.practice);
			}
		};
		const fetchPatient = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/patient/${patientId}`,
			);
			const responseData = await response.json();
			setPatient(responseData);
		};
		fetchSlot();
		fetchPatient();
		setIsLoading(false);
	}, []);

	if (isLoading || !slot || !patient || !practice) return <Loading />;

	if (slot.status === "claimed" && slot.claimedBy === patientId) {
		return (
			<PortalLayout>
				<div className="mt-10">
					<h1 className="text-3xl font-bold">
						U heeft deze plek al bevestigd
					</h1>
					<p className="text-gray-600 pt-3">
						We zien u in de praktijk!
					</p>
				</div>
			</PortalLayout>
		);
	}

	if (new Date(slot.date) < new Date()) {
		return (
			<>
				<div className="flex flex-col h-[100dvh] justify-between px-6 pt-24 pb-6">
					<div>
						<h1 className="text-3xl font-bold">
							Helaas, deze afspraak heeft al plaatsgevonden
						</h1>
						<p className="text-gray-600 pt-3">
							De vrijgekomen plek was op{" "}
							<FormattedDate date={slot.date} />.
						</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<PortalLayout>
				<div className="h-full flex flex-col justify-between">
					<div className="mt-8">
						<h1 className="text-3xl font-bold">Bevestig uw plek</h1>
						<p className="text-gray-600 pt-3">
							Goed nieuws! Er is onverwachts plek in de agenda
							vrijgekomen. Kijk goed of u op onderstaande datum en
							tijd bij de praktijk kunt zijn.
						</p>
						<div className="text-gray-600 pt-6">
							<p className=" flex items-center pb-2">
								<UserIcon className="h-5 w-5 text-brand-500 mr-3" />
								<p>
									{slot.behandelaar.firstName}
									{slot.behandelaar.lastName &&
										` ${slot.behandelaar.lastName}`}{" "}
									({slot.behandelaar.occupation.title})
								</p>
							</p>
							<p className="flex items-center pb-1.5">
								<CalendarDaysIcon className="h-5 w-5 text-brand-500 mr-3" />
								<FormattedDate date={slot.date} />
							</p>
							<p className="flex items-center pb-1.5">
								<ClockIcon className="h-5 w-5 text-brand-500 mr-3" />
								<p>
									{slot.startTime} - {slot.endTime} uur
								</p>
							</p>
						</div>

						<div className="border-slate-300 border bg-slate-50 rounded-2xl px-5 py-4 mt-5 mb-16">
							<h3 className="font-semibold text-md mb-1">
								{slot.location?.name}
							</h3>
							<p className="text-gray-500 flex items-center pb-1">
								{slot.location?.address.street}
							</p>
							<p className="text-gray-500 flex items-center">
								{slot.location?.address.postCode}{" "}
								{slot.location?.address.city}
							</p>
						</div>
					</div>
					<div>
						{fromSMS ? (
							<div className="space-y-3">
								<Button onClick={claimSlot} fullWidth>
									<CheckIcon className="h-5 w-5 mr-2" />
									Ja, ik kan
								</Button>
								<Button onClick={rejectSlot} fullWidth ghost>
									<XMarkIcon className="h-5 w-5 mr-2" />
									Nee, ik kan niet
								</Button>
							</div>
						) : (
							<Button onClick={claimSlot} fullWidth>
								Ja, bevestig de plek
							</Button>
						)}
					</div>
				</div>
			</PortalLayout>
			<ConfirmModal
				overrideColor={portal.portalSettings.primaryColor || null}
			/>
		</>
	);
};

export default ClaimSlotPage;
