import React, { useMemo, useState } from "react";
import DonutChart from "../../shared/components/Charts/DonutChart";
import {
	ChartBarIcon,
	ClockIcon,
	BuildingOfficeIcon,
	ChatBubbleOvalLeftIcon,
	CheckCircleIcon,
	ChevronDownIcon,
} from "@heroicons/react/16/solid";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@tremor/react";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import blueTicks from "../../assets/BlueTicks.png";

const TodaysSlotsStats = ({ statusCounts, occupationCounts, slots }) => {
	const [practicesDisplayCount, setPracticesDisplayCount] = useState(10);

	const statusData = Object.entries(statusCounts || {}).map(
		([name, value]) => ({
			name,
			value,
		}),
	);
	const occupationData = Object.entries(occupationCounts || {}).map(
		([name, value]) => ({ name, value }),
	);
	const totalSlots = Object.values(statusCounts || {}).reduce(
		(sum, count) => sum + count,
		0,
	);

	// Calculate average response time for claimed slots (excluding outliers of 120+ minutes)
	const avgResponseTime = useMemo(() => {
		const claimedSlots =
			slots?.filter((slot) => slot.status === "claimed") || [];
		if (claimedSlots.length === 0) return 0;

		let validResponsesCount = 0;
		const totalResponseTime = claimedSlots.reduce((sum, slot) => {
			if (slot.dateClaimed && slot.dateAcceptanceMessageSent) {
				const responseTime = Math.round(
					(new Date(slot.dateClaimed) -
						new Date(slot.dateAcceptanceMessageSent)) /
						60000,
				);
				// Skip outliers of 120+ minutes
				if (responseTime < 120) {
					validResponsesCount++;
					return sum + responseTime;
				}
			}
			return sum;
		}, 0);

		return validResponsesCount > 0
			? Math.round(totalResponseTime / validResponsesCount)
			: 0;
	}, [slots]);

	// Calculate status percentages
	const statusPercentages = useMemo(() => {
		if (!slots || slots.length === 0)
			return { claimed: 0, open: 0, deleted: 0, failed: 0 };

		const counts = {
			claimed: slots.filter((slot) => slot.status === "claimed").length,
			open: slots.filter((slot) => slot.status === "open").length,
			deleted: slots.filter((slot) => slot.status === "deleted").length,
			failed: slots.filter((slot) => slot.status === "failed").length,
		};

		return {
			claimed: Math.round((counts.claimed / slots.length) * 100),
			open: Math.round((counts.open / slots.length) * 100),
			deleted: Math.round((counts.deleted / slots.length) * 100),
			failed: Math.round((counts.failed / slots.length) * 100),
		};
	}, [slots]);

	// Calculate response rate percentage
	const responseRate = useMemo(() => {
		if (!slots || slots.length === 0) return 0;

		const totalInvites = slots.reduce(
			(sum, slot) => sum + (slot.totalInvitesSent || 0),
			0,
		);
		const totalResponses = slots.reduce(
			(sum, slot) => sum + (slot.totalInvitesResponded || 0),
			0,
		);

		return totalInvites > 0
			? Math.round((totalResponses / totalInvites) * 100)
			: 0;
	}, [slots]);

	// Calculate all practices sorted by filled slots
	const topPractices = useMemo(() => {
		if (!slots || slots.length === 0) return [];

		const practiceStats = {};

		// Count total and claimed slots for each practice
		slots.forEach((slot) => {
			const practiceId = slot.practice.id;
			const practiceName = slot.practice.name;

			if (!practiceStats[practiceId]) {
				practiceStats[practiceId] = {
					id: practiceId,
					name: practiceName,
					totalSlots: 0,
					claimedSlots: 0,
					totalInvitesSent: 0,
					totalInvitesRead: 0,
					totalInvitesResponded: 0,
				};
			}

			practiceStats[practiceId].totalSlots += 1;
			if (slot.status === "claimed") {
				practiceStats[practiceId].claimedSlots += 1;
			}

			// Add read and response rate data
			practiceStats[practiceId].totalInvitesSent +=
				slot.totalInvitesSent || 0;
			practiceStats[practiceId].totalInvitesRead +=
				slot.totalInvitesRead || 0;
			practiceStats[practiceId].totalInvitesResponded +=
				slot.totalInvitesResponded || 0;
		});

		// Convert to array and sort by claimed slots
		const sortedPractices = Object.values(practiceStats).sort(
			(a, b) => b.claimedSlots - a.claimedSlots,
		);

		// Add medals to top 3 and position numbers to the rest
		return sortedPractices.map((practice, index) => {
			if (index === 0) return { ...practice, prefix: "🥇" }; // Gold medal
			if (index === 1) return { ...practice, prefix: "🥈" }; // Silver medal
			if (index === 2) return { ...practice, prefix: "🥉" }; // Bronze medal
			return { ...practice, prefix: `#${index + 1}` }; // Position number
		});
	}, [slots]);

	// Determine emoji for status based on percentage
	const getStatusEmoji = (status, percentage) => {
		if (status === "claimed") {
			if (percentage >= 40) return "🔥"; // On fire
			if (percentage >= 35) return "👍"; // Good
			if (percentage >= 30) return "👌"; // Okay
			return "🙂"; // Needs improvement
		}
		return "📊";
	};

	return (
		<div className="bg-white rounded-3xl p-6 border-slate-200 border">
			<h3 className="font-bold text-sm uppercase text-slate-500 mb-4 flex gap-1.5 items-center">
				<ChartBarIcon className="size-4" />
				Statistieken
			</h3>

			<TabGroup>
				<TabList className="mb-4">
					<Tab className="flex items-center gap-1.5">Overzicht</Tab>

					<Tab className="flex items-center gap-1.5">
						Top 10 praktijken
					</Tab>
					<Tab>Verdeling</Tab>
				</TabList>
				<TabPanels>
					{/* Overzicht panel */}
					<TabPanel>
						<div className="grid grid-cols-2 gap-4">
							{/* Reaction time */}
							<div className="p-4 bg-slate-50 rounded-lg">
								<h4 className="text-sm font-medium mb-2 text-slate-700 flex items-center gap-1.5">
									<ClockIcon className="size-4 text-blue-600" />
									Gemiddelde reactietijd
								</h4>
								<div className="flex justify-between items-center">
									<span className="text-2xl font-bold text-blue-600">
										{avgResponseTime}{" "}
										<span className="text-sm font-normal">
											minuten
										</span>
									</span>
								</div>
							</div>

							{/* Response rate */}
							<div className="p-4 bg-slate-50 rounded-lg">
								<h4 className="text-sm font-medium mb-2 text-slate-700 flex items-center gap-1.5">
									<ChatBubbleOvalLeftIcon className="size-4 text-indigo-600" />
									Reactiegraad
								</h4>
								<div className="flex justify-between items-center">
									<span className="text-2xl font-bold text-indigo-600">
										{responseRate}%{" "}
										<span className="text-sm font-normal">
											reacties
										</span>
									</span>
								</div>
							</div>

							{/* Status Overview */}
							<div className="p-4 bg-slate-50 rounded-lg col-span-2 mt-2">
								<h4 className="text-sm font-medium mb-3 text-slate-700">
									Status
								</h4>
								<div className="grid grid-cols-2 gap-3">
									<div className="flex justify-between items-center">
										<span className="flex items-center">
											<span className="size-2 bg-green-500 rounded-full inline-block mr-2"></span>
											Gevuld
										</span>
										<span className="font-medium text-green-600">
											{getStatusEmoji(
												"claimed",
												statusPercentages.claimed,
											)}{" "}
											{statusPercentages.claimed}%
										</span>
									</div>

									<div className="flex justify-between items-center">
										<span className="flex items-center">
											<span className="size-2 bg-blue-500 rounded-full inline-block mr-2"></span>
											Open
										</span>
										<span className="font-medium text-blue-600">
											{getStatusEmoji(
												"open",
												statusPercentages.open,
											)}{" "}
											{statusPercentages.open}%
										</span>
									</div>

									<div className="flex justify-between items-center">
										<span className="flex items-center">
											<span className="size-2 bg-red-500 rounded-full inline-block mr-2"></span>
											Verwijderd
										</span>
										<span className="font-medium text-red-600">
											{getStatusEmoji(
												"deleted",
												statusPercentages.deleted,
											)}{" "}
											{statusPercentages.deleted}%
										</span>
									</div>

									<div className="flex justify-between items-center">
										<span className="flex items-center">
											<span className="size-2 bg-yellow-500 rounded-full inline-block mr-2"></span>
											Niemand kan
										</span>
										<span className="font-medium text-yellow-600">
											{getStatusEmoji(
												"failed",
												statusPercentages.failed,
											)}{" "}
											{statusPercentages.failed}%
										</span>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>

					{/* Top 5 Practices panel */}
					<TabPanel>
						<div className="space-y-3">
							<h4 className="text-sm font-medium mb-2 text-slate-700">
								Top 10 praktijken
							</h4>
							{topPractices.length > 0 ? (
								<div className="space-y-2">
									{topPractices
										.slice(0, practicesDisplayCount)
										.map((practice) => (
											<div
												key={practice.id}
												className="p-3 bg-slate-50 rounded-lg"
											>
												<div className="flex items-center justify-between">
													<span className="font-medium">
														{practice.prefix}{" "}
														{practice.name}
													</span>
													<div className="flex items-center gap-3">
														{/* Read rate */}
														<Tooltip
															content={`${practice.totalInvitesRead}/${practice.totalInvitesSent} gelezen`}
															id={`read-rate_practice_${practice.id}`}
														>
															<p className="text-sm font-medium flex gap-x-1 items-center">
																<img
																	src={
																		blueTicks
																	}
																	alt="Read"
																	className="h-[11px] w-auto"
																/>
																{practice.totalInvitesSent >
																0
																	? Math.round(
																			(practice.totalInvitesRead /
																				practice.totalInvitesSent) *
																				100,
																		)
																	: 0}
																%
															</p>
														</Tooltip>

														{/* Response rate */}
														<Tooltip
															content={`${practice.totalInvitesResponded}/${practice.totalInvitesSent} gereageerd`}
															id={`response-rate_practice_${practice.id}`}
														>
															<p className="text-sm font-medium flex gap-x-1 items-center">
																<ChatBubbleOvalLeftIcon className="size-4 text-slate-400" />
																{practice.totalInvitesSent >
																0
																	? Math.round(
																			(practice.totalInvitesResponded /
																				practice.totalInvitesSent) *
																				100,
																		)
																	: 0}
																%
															</p>
														</Tooltip>

														{/* Slots filled */}
														<span className="font-medium text-sm ml-12">
															<span className="text-green-700 font-bold text-lg">
																{
																	practice.claimedSlots
																}
															</span>
															/
															{
																practice.totalSlots
															}
															<span className="text-slate-500 ml-1">
																(
																{Math.round(
																	(practice.claimedSlots /
																		practice.totalSlots) *
																		100,
																)}
																%)
															</span>
														</span>
													</div>
												</div>
											</div>
										))}
									{practicesDisplayCount <
										topPractices.length && (
										<button
											onClick={() =>
												setPracticesDisplayCount(
													practicesDisplayCount + 10,
												)
											}
											className="w-full mt-3 py-2 px-4 bg-slate-50 hover:bg-slate-100 rounded-lg text-sm font-medium text-blue-600 flex items-center justify-center gap-1 transition-colors"
										>
											Laat de volgende 10 zien
											<ChevronDownIcon className="size-4" />
										</button>
									)}
								</div>
							) : (
								<p className="text-slate-500 text-sm">
									Geen gegevens beschikbaar
								</p>
							)}
						</div>
					</TabPanel>

					{/* Distribution charts panel */}
					<TabPanel>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<h4 className="text-sm font-medium mb-2 text-slate-700">
									Status verdeling
								</h4>
								<DonutChart
									data={statusData}
									category="value"
									index="name"
									variant="pie"
									valueFormatter={(value) =>
										`${value}x (${Math.round((value / totalSlots) * 100)}%)`
									}
									colors={[
										"green-500",
										"blue-600",
										"red-600",
										"yellow-600",
									]}
									includeLegend={true}
									className="w-20"
								/>
							</div>

							<div>
								<h4 className="text-sm font-medium mb-2 text-slate-700">
									Beroep verdeling
								</h4>
								<DonutChart
									data={occupationData}
									category="value"
									variant="pie"
									index="name"
									valueFormatter={(value) =>
										`${value}x (${Math.round((value / totalSlots) * 100)}%)`
									}
									colors={[
										"indigo-500",
										"cyan-500",
										"pink-500",
										"orange-500",
										"emerald-500",
									]}
									includeLegend={true}
									className="w-20"
								/>
							</div>
						</div>
					</TabPanel>
				</TabPanels>
			</TabGroup>
		</div>
	);
};

export default TodaysSlotsStats;
