import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";
import Container from "../../../shared/components/UIElements/Container";
import Heading from "../../../shared/components/UIElements/Heading";
import MiniBackButton from "../../../shared/components/FormElements/MiniBackButton";
import CopyText from "../../../shared/components/FormElements/CopyText";
import CheckList from "../../../shared/components/UIElements/CheckList";

import { Link } from "react-router-dom";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";

const PatientInfoSite = () => {
	const [practice, setPractice] = useState("");
	const { user } = useUser();
	const fetchPractice = useFetch();

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await fetchPractice(
				`/api/practice/user/${user.id}`,
				"GET",
			);
			setPractice(practiceReq);
		};

		getPractice();
	}, [fetchPractice, user]);

	// const encodedPractice = encodeURIComponent(practice);
	const link = `https://app.snelterecht.nl/praktijk/${practice._id}`;

	return (
		<Container>
			<MiniBackButton to="/help" />

			<Heading>Site met informatie voor patiënten</Heading>
			<p className="text-gray-600 mt-2 mb-6">
				Deze site is bedoeld om de meest gestelde vragen over
				Snelterecht te beantwoorden voor patiënten.
			</p>

			<CheckList
				items={[
					"Voor op jullie praktijk website",
					"Voor in de nieuwsbrief naar patiënten",
					"Voor onderaan de afspraakbevestiging",
				]}
			/>

			<div className="mt-10">
				<h4 className="text-lg font-semibold">
					Deel deze link met je patiënten
				</h4>
				<p className="text-sm text-gray-500 mt-1 mb-4">
					De link is uniek per praktijk, het bevat jullie
					praktijknaam.
				</p>
				{practice && (
					<>
						<div className="w-1/2 ">
							<CopyText text={link} />
						</div>
						<Link
							to={link}
							target="_blank"
							className="text-brand-intense font-semibold flex text-sm items-center gap-1 pt-2"
						>
							Bekijken
							<ArrowUpRightIcon className="size-4" />
						</Link>
					</>
				)}
			</div>
		</Container>
	);
};

export default PatientInfoSite;
