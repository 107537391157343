import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import Container from "../../shared/components/UIElements/Container";
import SettingsSideBar from "../../users/components/settings/SettingsSideBar";
import BehandelaarTable from "../../practice/components/Behandelaren/BehandelaarTable";
import {
	ArchiveBoxXMarkIcon,
	BanknotesIcon,
	CalendarDaysIcon,
	ChartBarIcon,
	EyeIcon,
	ChatBubbleOvalLeftIcon,
	MapIcon,
	PhotoIcon,
	UserGroupIcon,
	UsersIcon,
	CursorArrowRippleIcon,
	MapPinIcon,
	ChatBubbleOvalLeftEllipsisIcon,
	ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import PracticeStats from "./subpages/PracticeStats";
import UserOverview from "./subpages/UserOverview";
import PracticesMap from "./subpages/PracticesMap";
import IntegrationResultsExperiment from "./subpages/IntegrationResultsExperiment";
import SinglePractice from "./subpages/SinglePractice";
import ChurnedPractices from "./subpages/ChurnedPractices";
import TodaysSlots from "./subpages/TodaysSlots";
import PatientsEyes from "./subpages/PatientsEyes";
import MessageStatistics from "./subpages/MessageStatistics";
import ClaimedPatients from "./subpages/ClaimedPatients";
import PracticesWithMultipleLocations from "./subpages/PracticesWithMultipleLocations";
import FreeformMessages from "./subpages/FreeformMessages";
import HighChurnRisks from "./subpages/HighChurnRisks";

const AdminDashboard = () => {
	const { user } = useUser();
	const location = useLocation();
	const navigate = useNavigate();

	const secondaryNavigation = [
		{
			title: "Statistieken",
			items: [
				{
					name: "Plekken gemeld",
					href: "/admin/slots",
					icon: CalendarDaysIcon,
					component: TodaysSlots,
				},
				{
					name: "Patiënten gevuld",
					href: "/admin/patients-claimed",
					icon: CursorArrowRippleIcon,
					component: ClaimedPatients,
				},
				{
					name: "Statistieken",
					href: "/admin/stats",
					icon: ChartBarIcon,
					component: PracticeStats,
				},
				{
					name: "Gebruikers",
					href: "/admin/users",
					icon: UsersIcon,
					component: UserOverview,
				},
				{
					name: "Landkaart",
					href: "/admin/practices-map",
					icon: MapIcon,
					component: PracticesMap,
				},
				{
					name: "Gestopte praktijken",
					href: "/admin/churned-practices",
					icon: ArchiveBoxXMarkIcon,
					component: ChurnedPractices,
				},
				{
					name: "Single practice",
					href: "/admin/practice/",
					icon: MapIcon,
					component: SinglePractice,
					hide: true,
				},
				{
					name: "Meerdere locaties",
					href: "/admin/practices-multiple-locations",
					icon: MapPinIcon,
					component: PracticesWithMultipleLocations,
				},

				// todo: warning: users with an active subscription but who haven't filled many slots
				// todo: churn insights
			],
		},
		{
			title: "Controles",
			items: [
				{
					name: "High churn risks",
					href: "/admin/high-churn-risks",
					icon: ExclamationTriangleIcon,
					component: HighChurnRisks,
				},
				{
					name: "Freeform berichten",
					href: "/admin/freeform-messages",
					icon: ChatBubbleOvalLeftEllipsisIcon,
					component: FreeformMessages,
				},
				// {
				// 	name: "Koppeling experiment",
				// 	href: "/admin/integratie",
				// 	icon: PhotoIcon,
				// 	component: IntegrationResultsExperiment,
				// },

				{
					name: "Patienten ogen",
					href: "/admin/patient-eyes",
					icon: EyeIcon,
					component: PatientsEyes,
				},
			],
		},
		{
			title: "Shortcuts",
			items: [
				{
					name: "CRM",
					href: "https://airtable.com/appcnhXcNIFmr3j2g/tblvfs4b3f5DKnRhI/viwzlqQOMPATSqloR?blocks=hide",
					icon: UserGroupIcon,
					external: true,
				},
				{
					name: "Stripe",
					icon: BanknotesIcon,
					href: "https://dashboard.stripe.com/billing/revenue",
					external: true,
				},
			],
		},
	];

	const getActiveItem = () => {
		const pathname = location.pathname.split("?")[0].replace(/\/$/, "");
		for (const section of secondaryNavigation) {
			for (const item of section.items) {
				if (pathname === item.href.replace(/\/$/, "")) {
					return item;
				}
			}
		}
		return secondaryNavigation[0].items[0];
	};

	const [activeItem, setActiveItem] = useState(getActiveItem());

	useEffect(() => {
		setActiveItem(getActiveItem());
	}, [location.pathname]);

	if (user.publicMetadata.role !== "admin") {
		return <Navigate to="/" />;
	}

	return (
		<div>
			<SettingsSideBar
				navItems={secondaryNavigation}
				isAdmin={true}
				setActiveItem={(item) => {
					setActiveItem(item);
					navigate(item.href);
				}}
				activeItem={activeItem}
			>
				<Container transparent>
					{activeItem.component
						? React.createElement(activeItem.component)
						: null}
				</Container>
			</SettingsSideBar>
		</div>
	);
};

export default AdminDashboard;
