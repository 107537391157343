import React from "react";
import {
	MapPinIcon,
	CalendarIcon,
	PhoneIcon,
	ClockIcon,
	UserGroupIcon,
} from "@heroicons/react/16/solid";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import { format, differenceInDays } from "date-fns";
import { nl } from "date-fns/locale";

const getActivityStatus = (lastActivity) => {
	const daysSinceActivity = differenceInDays(
		new Date(),
		new Date(lastActivity),
	);

	if (daysSinceActivity <= 7) {
		return {
			color: "bg-green-500",
			text: "Actief afgelopen 7 dagen",
		};
	} else if (daysSinceActivity <= 14) {
		return {
			color: "bg-orange-500",
			text: "Actief afgelopen 14 dagen",
		};
	}
	return {
		color: "bg-red-500",
		text: "Niet actief afgelopen 14 dagen",
	};
};

const SinglePracticeItem = ({ practice }) => {
	const locationCount = practice.locations?.length || 0;
	const activityStatus = getActivityStatus(practice.lastActivity);

	// Assuming each phone call saved is about 3 minutes
	const minutesSaved = practice.stats.messages * 3;
	const hoursSaved = Math.floor(minutesSaved / 60);
	const remainingMinutes = minutesSaved % 60;

	return (
		<div className="bg-white rounded-3xl p-6 hover:shadow-lg hover:shadow-opa duration-300 transition-shadow">
			{practice.settings?.patientPortal?.logoUrl ? (
				<img
					src={practice.settings.patientPortal.logoUrl}
					alt={practice.name}
					className="mb-2 w-40 max-h-20"
				/>
			) : (
				<div className="w-10 h-10 rounded-full bg-gray-200 mb-2 flex items-center justify-center">
					<span className="text-gray-500 text-xs font-medium">
						{practice.name?.substring(0, 2).toUpperCase() || ""}
					</span>
				</div>
			)}

			<div className="flex justify-between items-start mb-4">
				<h2 className="text-xl font-semibold text-gray-900">
					{practice.name}
				</h2>
			</div>

			<Tooltip
				id={`practice-${practice._id}`}
				content={`Laatste activiteit op ${format(
					new Date(practice.lastActivity),
					"d MMMM yyyy",
					{
						locale: nl,
					},
				)}`}
			>
				<div className="flex items-center mb-2">
					<div
						className={`h-3 w-3 rounded-full ${activityStatus.color}`}
					/>
					<p
						className={`ml-2 font-medium text-sm ${activityStatus.color.replace("bg-", "text-")}`}
					>
						{activityStatus.text}
					</p>
				</div>
			</Tooltip>
			<div className="space-y-3">
				<div className="pt-4 space-y-2">
					<div className="flex items-center text-gray-600">
						<ClockIcon className="h-5 w-5 mr-2" />
						<span>
							{practice.stats.claimedSlots} plekken gevuld
						</span>
					</div>

					<div className="flex items-center text-gray-600">
						<UserGroupIcon className="h-5 w-5 mr-2" />
						<span>
							{practice.stats.patients} patiënten toegevoegd
						</span>
					</div>

					<div className="flex items-center text-gray-600">
						<PhoneIcon className="h-5 w-5 mr-2" />
						<span>
							{practice.stats.messages} telefoontjes bespaard
							<span className="text-sm text-gray-500 ml-1">
								({hoursSaved}u {remainingMinutes}m bespaard)
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SinglePracticeItem;
