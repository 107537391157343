import React, { useState, useEffect, useCallback } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";
import SinglePracticeItem from "./SinglePracticeItem";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import { ClockIcon, UserGroupIcon, PhoneIcon } from "@heroicons/react/16/solid";

const ChainPractices = ({ dateRange, selectedChain }) => {
	const { user } = useUser();
	const fetchData = useFetch();
	const [practices, setPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);

	const loadPractices = useCallback(async () => {
		try {
			const response = await fetchData(
				`/api/chain/practices/${user?.id}/analytics?from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}&chain=${selectedChain}`,
			);
			if (!response.error) {
				setPractices(response.practices);
				setIsAdmin(response.isAdmin);
			} else {
				setError(response.error);
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	}, [fetchData, user?.id, dateRange, selectedChain]);

	useEffect(() => {
		loadPractices();
	}, [loadPractices]);

	if (isLoading) {
		return (
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				{[...Array(6)].map((_, index) => (
					<div key={index} className="bg-white rounded-3xl p-6">
						<div className="w-10 h-10 rounded-full bg-gray-200 mb-2 flex items-center justify-center">
							<span className="text-gray-300 text-xs font-medium">
								AB
							</span>
						</div>
						<div className="flex justify-between items-start mb-4">
							<div className="h-6 w-3/4 bg-gray-200 rounded"></div>
						</div>
						<div className="flex items-center mb-2">
							<div className="h-3 w-3 rounded-full bg-gray-200" />
							<div className="ml-2 h-4 w-1/2 bg-gray-200 rounded"></div>
						</div>
						<div className="space-y-3">
							<div className="pt-4 space-y-2">
								<div className="flex items-center text-gray-300">
									<ClockIcon className="h-5 w-5 mr-2" />
									<div className="h-4 w-1/2 bg-gray-200 rounded"></div>
								</div>
								<div className="flex items-center text-gray-300">
									<UserGroupIcon className="h-5 w-5 mr-2" />
									<div className="h-4 w-2/3 bg-gray-200 rounded"></div>
								</div>
								<div className="flex items-center text-gray-300">
									<PhoneIcon className="h-5 w-5 mr-2" />
									<div className="h-4 w-3/4 bg-gray-200 rounded"></div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	if (error) {
		return (
			<div className="text-center text-red-600 p-4">
				Er is een fout opgetreden bij het ophalen van de praktijken.
			</div>
		);
	}

	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				{practices.map((practice) => (
					<SinglePracticeItem
						key={practice._id}
						practice={practice}
					/>
				))}
			</div>

			{practices.length === 0 && (
				<div className="text-center text-gray-500 mt-8 col-span-full">
					Geen praktijken gevonden.
				</div>
			)}
		</>
	);
};

export default ChainPractices;
