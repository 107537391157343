import React, { useState } from "react";
import { toast } from "react-toastify";
import { HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/16/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Modal from "../../../shared/components/UIElements/Modal";
import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";

const FEEDBACK_OPTIONS = [
	{ id: "wrongPatient", label: "Verkeerde patiënt overgenomen" },
	{ id: "wrongName", label: "Alleen verkeerde naam overgenomen" },
	{ id: "wrongPhone", label: "Telefoonnummer klopt niet" },
	{ id: "wrongPatientNumber", label: "Patiëntnummer klopt niet" },
	{ id: "other", label: "Anders" },
];

const PatientDataRating = ({ documentId, accessKey }) => {
	const [isAccurate, setIsAccurate] = useState(null);
	const [ratingSubmitted, setRatingSubmitted] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [selectedFeedback, setSelectedFeedback] = useState([]);
	const [feedbackComment, setFeedbackComment] = useState("");
	const fetchData = useFetch();

	const handleRating = async (accurate) => {
		if (!documentId || !accessKey) return;

		// If user clicks on the same rating again, do nothing
		if (isAccurate === accurate && ratingSubmitted) return;

		// Set the accuracy state
		setIsAccurate(accurate);

		// Submit the rating immediately, whether positive or negative
		await submitRating(accurate);

		// If negative, also show the feedback modal for additional details
		if (!accurate) {
			setShowFeedbackModal(true);
		}
	};

	const submitRating = async (accurate, feedbackItems = [], comment = "") => {
		try {
			const response = await fetchData(
				`/api/desktop/integration-results/${documentId}/rate`,
				{
					method: "POST",
					body: JSON.stringify({
						key: accessKey,
						isAccurate: accurate,
						feedbackItems: feedbackItems,
						comment: comment,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				},
			);

			setRatingSubmitted(true);

			// Only show toast for initial rating or when submitting feedback form
			if (feedbackItems.length > 0 || accurate) {
				toast.success(response.message || "Bedankt voor je feedback!", {
					position: "bottom-center",
				});
			}
		} catch (error) {
			console.error("Error submitting rating:", error);
			// We keep the rating state, but don't mark as submitted so user can try again
		}
	};

	const handleFeedbackSelection = (optionId) => {
		setSelectedFeedback((prev) => {
			if (prev.includes(optionId)) {
				return prev.filter((id) => id !== optionId);
			} else {
				return [...prev, optionId];
			}
		});
	};

	const handleFeedbackSubmit = () => {
		// Submit additional feedback
		submitRating(false, selectedFeedback, feedbackComment);
		setShowFeedbackModal(false);

		// Show a specific thank you message for the detailed feedback
		toast.success("Bedankt voor je uitgebreide feedback!", {
			position: "bottom-center",
		});
	};

	return (
		<>
			<div className="mt-3 flex items-center justify-between">
				<span className="text-sm text-slate-700">
					Kloppen de gegevens?
				</span>
				<div className="[&>*]:p-2 [&>*]:rounded-md flex gap-1">
					<button
						onClick={() => handleRating(true)}
						className={`${isAccurate === true ? "bg-green-100" : "hover:bg-slate-100"}`}
						aria-label="Thumbs up"
					>
						<HandThumbUpIcon
							className={`size-4 ${isAccurate === true ? "text-green-600" : "text-slate-500"}`}
						/>
					</button>
					<button
						onClick={() => handleRating(false)}
						className={`${isAccurate === false ? "bg-red-100" : "hover:bg-slate-100"}`}
						aria-label="Thumbs down"
					>
						<HandThumbDownIcon
							className={`size-4 ${isAccurate === false ? "text-red-600" : "text-slate-500"}`}
						/>
					</button>
				</div>
			</div>

			<Modal
				open={showFeedbackModal}
				setOpen={setShowFeedbackModal}
				includeCloseButton={false}
			>
				<div className="p-6">
					<div className="flex justify-between items-center mb-6">
						<h3 className="text-xl font-bold">
							Geef meer feedback
						</h3>
						<button onClick={() => setShowFeedbackModal(false)}>
							<XMarkIcon className="h-6 w-6 text-gray-400" />
						</button>
					</div>

					<div className="border-t border-gray-200 my-4"></div>

					<div className="flex flex-wrap gap-2 mb-6">
						{FEEDBACK_OPTIONS.map((option) => (
							<button
								key={option.id}
								onClick={() =>
									handleFeedbackSelection(option.id)
								}
								className={`px-4 py-2 rounded-full text-sm ${
									selectedFeedback.includes(option.id)
										? "bg-black text-white border-gray-300 font-medium"
										: "bg-white border-gray-300 hover:bg-gray-50"
								} border `}
							>
								{option.label}
							</button>
						))}
					</div>

					<div className="mb-6">
						<textarea
							placeholder="(Optioneel) Geef hier specifieke details"
							value={feedbackComment}
							onChange={(e) => setFeedbackComment(e.target.value)}
							className="w-full p-3 border border-gray-300 rounded-lg text-sm"
							rows={3}
						></textarea>
					</div>

					<div className="flex justify-end">
						<Button size="medium" onClick={handleFeedbackSubmit}>
							Verzenden
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default PatientDataRating;
