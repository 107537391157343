import React, { createContext, useContext, useState, useEffect } from "react";
import useFetch from "../../shared/hooks/useFetch";
import { useParams, useLocation } from "react-router-dom";
import Loading from "../../shared/pages/Loading";

const PortalContext = createContext();

export const PortalProvider = ({ children }) => {
	const [portalSettings, setPortalSettings] = useState(null);
	const [previewSettings, setPreviewSettings] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { patientId: paramPatientId } = useParams();
	const { praktijkId } = useParams();
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const queryPatientId = queryParams.get("pt");
	const alternativePatientId = queryParams.get("patientId");
	const patientId = paramPatientId || queryPatientId || alternativePatientId;
	const fetch = useFetch();

	useEffect(() => {
		const fetchPortalSettings = async () => {
			if (!patientId && !praktijkId) {
				setIsLoading(false);
				return;
			}

			const query = praktijkId
				? `praktijkId=${praktijkId}`
				: `patientId=${patientId}`;
			const res = await fetch(`/api/public/portal-settings/?${query}`);
			setPortalSettings(res);
			setIsLoading(false);
		};

		fetchPortalSettings();
	}, [patientId, fetch]);

	const updatePortalSettings = (newSettings) => {
		setPreviewSettings(newSettings);
	};

	// Use preview settings if available, otherwise use fetched settings
	const activeSettings = previewSettings || portalSettings;

	if (isLoading && patientId) {
		return <Loading />;
	}

	return (
		<PortalContext.Provider
			value={{
				portalSettings: activeSettings,
				updatePortalSettings,
				isPreview: !!previewSettings,
			}}
		>
			{children}
		</PortalContext.Provider>
	);
};

export const usePortal = () => {
	const context = useContext(PortalContext);
	if (!context) {
		throw new Error("usePortal must be used within a PortalProvider");
	}
	return context;
};
