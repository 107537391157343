import React, { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, CalendarIcon } from "@heroicons/react/16/solid";
import { addDays, format } from "date-fns";
import { nl } from "date-fns/locale";

const PeriodSelector = ({ onPeriodChange }) => {
	const [selectedPeriod, setSelectedPeriod] = useState(30);
	const periods = [
		{ label: "Laatste 14 dagen", days: 14 },
		{ label: "Laatste 30 dagen", days: 30 },
		{ label: "Laatste 90 dagen", days: 90 },
		{ label: "Laatste 365 dagen", days: 365 },
	];

	useEffect(() => {
		// Default to 30 days on initial load
		handlePeriodSelect(30);
	}, []);

	const handlePeriodSelect = (days) => {
		setSelectedPeriod(days);
		const to = new Date();
		const from = addDays(new Date(), -days);

		onPeriodChange({
			from,
			to,
		});
	};

	const getSelectedPeriodLabel = () => {
		const period = periods.find((p) => p.days === selectedPeriod);
		return period ? period.label : "Selecteer periode";
	};

	const formatDate = (date) => {
		return format(date, "d MMMM yyyy", { locale: nl });
	};

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					<CalendarIcon className="w-5 h-5 text-gray-400" />
					{getSelectedPeriodLabel()}
					<ChevronDownIcon
						className="w-5 h-5 ml-1 -mr-1 text-gray-400"
						aria-hidden="true"
					/>
				</Menu.Button>
				<Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{periods.map((period) => (
							<Menu.Item key={period.days}>
								{({ active }) => (
									<button
										className={`${
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700"
										} group flex flex-col items-start w-full px-4 py-2 text-sm text-left`}
										onClick={() =>
											handlePeriodSelect(period.days)
										}
									>
										<div className="text-left font-semibold">
											{period.label}
										</div>
										<div className="text-xs text-gray-500 text-left">
											(
											{formatDate(
												addDays(
													new Date(),
													-period.days,
												),
											)}{" "}
											- {formatDate(new Date())})
										</div>
									</button>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Menu>
		</div>
	);
};

export default PeriodSelector;
