import { useEffect, useState } from "react";

import {
	HomeIcon,
	ArrowTrendingUpIcon,
	LifebuoyIcon,
	UserGroupIcon,
	CalendarIcon,
	BuildingLibraryIcon,
	BuildingOffice2Icon,
	CogIcon,
} from "@heroicons/react/24/outline";
import {
	ArrowPathRoundedSquareIcon,
	ArrowsRightLeftIcon,
} from "@heroicons/react/20/solid";
import NavItem from "./NavItem";

import { useUser } from "@clerk/clerk-react";

import useFetch from "../../hooks/useFetch";
import { useTaskContext } from "../../../tasks/context/TaskContext";

const NavList = () => {
	const [notifs, setNotifs] = useState([]);
	const fetchNotifications = useFetch();
	const { user } = useUser();
	const { tasksUpdated } = useTaskContext();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchNotifications(
				`/api/tasks/user/${user.id}/open`,
			);
			setNotifs(response.length);
		};

		getNotifications();
	}, [tasksUpdated]);

	const isChainAdmin = user.publicMetadata.role === "chain-analytics";

	const chainAdminNavItems = [
		{
			name: "Statistieken",
			href: "/keten/",
			icon: BuildingOffice2Icon,
		},
	];

	const navigation = [
		{ name: "Dashboard", href: "/", icon: HomeIcon, classNames: "pb-9" },

		{
			name: "Patiënten",
			href: "/patienten/all",
			icon: UserGroupIcon,
			plusIcon: true,
			plusUrl: "/patienten/nieuw/",
		},
		{
			name: "Lege plekken",
			href: "/gaten/",
			icon: CalendarIcon,
			includeCounter: true,
			plusIcon: true,
			plusUrl: "/gaten/nieuw/",
		},
		{
			name: "Oproepen",
			href: "/reactivatie/",
			// classNames: "pb-9",
			icon: ArrowPathRoundedSquareIcon,
		},
		{
			name: "Naar voren halen",
			href: "/verplaatsen/",
			icon: ArrowsRightLeftIcon,
			classNames: "pb-9",
		},

		{
			name: "Overzicht",
			href: "/overzicht/",
			icon: ArrowTrendingUpIcon,
		},

		{
			name: "Hulp nodig?",
			href: "/help",
			icon: LifebuoyIcon,
		},
		{
			name: "Instellingen",
			href: "/instellingen/",
			icon: CogIcon,
		},
		{
			name: "Admin",
			href: "/admin/",
			icon: BuildingLibraryIcon,
			hide: user.publicMetadata.role !== "admin",
		},
		{
			name: "Ketens",
			href: "/keten/",
			icon: BuildingOffice2Icon,
			hide: user.publicMetadata.role !== "admin",
		},
	];

	const navItems = isChainAdmin ? chainAdminNavItems : navigation;

	return (
		<>
			{navItems.map((item) => (
				<>
					<NavItem item={item} />
				</>
			))}
		</>
	);
};

export default NavList;
