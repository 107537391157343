import React, { useState } from "react";
import ChainPractices from "../components/ChainPractices";
import Heading from "../../shared/components/UIElements/Heading";
import PeriodSelector from "../components/PeriodSelector";
import { useUser } from "@clerk/clerk-react";
import chains from "../../assets/constants/chains";
import Container from "../../shared/components/UIElements/Container";
import ChainPicker from "../components/ChainPicker";
import { Navigate } from "react-router-dom";

const ChainDashboard = () => {
	const { user } = useUser();
	const [selectedChain, setSelectedChain] = useState(
		user?.publicMetadata?.chain,
	);
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});

	const handlePeriodChange = (period) => {
		setDateRange(period);
	};

	const isAdmin = user?.publicMetadata?.role === "admin";
	const chainName = chains[selectedChain];

	if (
		user.publicMetadata.role !== "admin" &&
		user.publicMetadata.role !== "chain-analytics"
	) {
		return <Navigate to="/" />;
	}

	return (
		<div className="bg-[#e2eaf599] bg-opacity-60">
			<Container transparent>
				<div className="flex items-center justify-between mb-10">
					<Heading>Keten dashboard: {chainName}</Heading>
					<div className="flex items-center gap-4">
						{isAdmin && (
							<ChainPicker
								selectedChain={selectedChain}
								onChange={setSelectedChain}
							/>
						)}
						<PeriodSelector onPeriodChange={handlePeriodChange} />
					</div>
				</div>
				<ChainPractices
					key={`${dateRange.from.getTime()}-${selectedChain}`}
					dateRange={dateRange}
					selectedChain={selectedChain}
				/>
			</Container>
		</div>
	);
};

export default ChainDashboard;
