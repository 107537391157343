import React, { useEffect, useState } from "react";
import SidePanel from "../../shared/components/UIElements/SidePanel";
import useFetch from "../../shared/hooks/useFetch";
import CheckTask from "../components/CheckTask";

import {
	CalendarIcon,
	ChatBubbleBottomCenterIcon,
	ClockIcon,
	UserIcon,
} from "@heroicons/react/24/outline";
import FormattedDate from "../../shared/components/util/FormattedDate";
import CopyText from "../../shared/components/FormElements/CopyText";
import YellowNote from "../../patients/components/YellowNote";

const TaskDetail = ({ taskId, open, setOpen, setTasks, taskItem }) => {
	const [task, setTask] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const fetchTask = useFetch();

	useEffect(() => {
		if (open) {
			const fetchSingleTask = async () => {
				if (!taskId) return;
				const response = await fetchTask(`/api/tasks/${taskId}`);
				setTask(response);
				setIsLoading(false);
			};

			fetchSingleTask();
		}
	}, [fetchTask, taskId, open, taskItem]);

	return (
		<SidePanel open={open} setOpen={setOpen}>
			<div className="px-6 py-12">
				{isLoading && <p className="text-gray-500">Aan het laden...</p>}
				{!isLoading && task && (
					<>
						<div className="flex items-center mb-6">
							<CheckTask
								taskId={taskId}
								task={task}
								setTasks={setTasks}
								isChecked={task.isChecked}
							/>
							<h1 className="text-2xl font-bold pl-3">
								Afspraak verzetten
							</h1>
						</div>

						<p className="text-gray-600 text-sm mt-2 mb-5">
							Verzet de afspraak van{" "}
							<strong className="text-black">
								{task.patient.name}
							</strong>{" "}
							in je praktijkagenda
						</p>
						<div>
							<p className="font-semibold text-sm">
								Patiëntnummer
							</p>
							<CopyText text={task.patient.patientNumber} />
						</div>
						{task.patient.notes && (
							<div className="mt-6">
								<YellowNote>{task.patient.notes}</YellowNote>
							</div>
						)}
						<div>
							{task.patient?.type !== "reschedule" && (
								<div className="border-slate-300 border bg-slate-50 rounded-2xl px-6 py-4 mt-8">
									<h3 className="font-semibold text-md mb-2">
										Oude afspraak
									</h3>

									<p className="text-gray-600 flex items-center pb-1.5 text-sm">
										<CalendarIcon className="h-5 w-5 text-gray-500 mr-3" />
										<FormattedDate
											date={
												task.patient?.appointment
													?.datePlanned
											}
											errorMessage="Nog geen afspraak ingepland"
										/>
									</p>
								</div>
							)}

							<div className="border-slate-300 border bg-slate-50 rounded-2xl px-6 py-4 mt-4">
								<h3 className="font-semibold text-md mb-2">
									Nieuwe afspraak
								</h3>
								<p className="text-gray-500 flex items-center pb-2 text-sm">
									<UserIcon className="h-5 w-5 text-gray-500 mr-3" />
									<p className="text-gray-600">
										{task.slot.behandelaar.firstName}{" "}
										{task.slot.behandelaar.lastName}
									</p>
								</p>
								<p className="text-gray-600 flex items-center pb-1.5 text-sm">
									<CalendarIcon className="h-5 w-5 text-gray-500 mr-3" />
									<FormattedDate date={task.slot.date} />
								</p>
								<p className="text-gray-500 flex items-center pb-1.5 text-sm">
									<ClockIcon className="h-5 w-5 text-gray-500 mr-3" />
									<p className="text-gray-600">
										{task.slot.startTime}{" "}
										{task.endTime
											? `- ${task.endTime}`
											: ""}{" "}
										uur
									</p>
								</p>
							</div>
						</div>
					</>
				)}
			</div>
		</SidePanel>
	);
};

export default TaskDetail;
