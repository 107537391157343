import React from "react";
import Container from "../../shared/components/UIElements/Container";
import { SparklesIcon } from "@heroicons/react/16/solid";
import ChatBubble from "../../shared/components/UIElements/ChatBubble";
import { useUser } from "@clerk/clerk-react";

const RescheduleInfoPage = () => {
	const { user } = useUser();

	const practiceName =
		user?.unsafeMetadata?.praktijkNaam || "De Stralende Lach";

	return (
		<Container>
			<div className="flex flex-row gap-4">
				<div className="w-1/3">
					<ChatBubble
						isOutgoing={false}
						profilePicture="https://snelterecht.nl/wp-content/uploads/2024/04/cropped-android-chrome-72x72-1-180x180.png"
					>
						<>
							Goedemiddag! U heeft binnenkort een afspraak bij{" "}
							<b>{practiceName}</b>. Er is wat veranderd in onze
							planning waardoor er onverwachts eerder een plek is
							vrijgekomen bij Sanne (Mondhygiënist).
							<br />
							<br />
							🗓️{" "}
							{new Date(
								Date.now() + 3 * 24 * 60 * 60 * 1000,
							).toLocaleDateString("nl-NL", {
								weekday: "long",
								day: "numeric",
								month: "long",
							})}
							<br />
							🕒 12:00 uur
							<br />
							<br />
							Zou het u schikken om op dit tijdstip langs te komen
							in plaats van uw originele afspraak? <br />
						</>
					</ChatBubble>
				</div>
				<div className="w-2/3 my-auto">
					<span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-brand-100 text-brand-800 mb-3 gap-1.5">
						<SparklesIcon className="size-3.5" />
						Nieuw in Snelterecht
					</span>
					<h1 className="text-2xl font-bold text-balance">
						Lukt het niet om een plek op te vullen via de
						wachtlijst? Je kunt ook patiënten 'naar voren halen'
					</h1>
					<p className="text-slate-600 mt-4">
						De patiënt komt dan niet op de wachtlijst te staan, maar
						ontvangt éénmalig een uitnodiging om eerder langs te
						komen.
					</p>
					<p className="text-slate-600 mt-4">
						Dit kun je doen door in de patiëntenkaart op het
						Snelterecht icoon te klikken en vervolgens voor{" "}
						<b>'Naar voren halen'</b> te kiezen.
					</p>
					<p className="text-slate-600 mt-4">
						Vragen? Stel ze vooral via de chat!
					</p>
				</div>
			</div>
		</Container>
	);
};

export default RescheduleInfoPage;
