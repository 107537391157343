import React, { useEffect } from "react";
import { BuildingOfficeIcon, ChevronDownIcon } from "@heroicons/react/16/solid";
import chains from "../../assets/constants/chains";
import { Listbox } from "@headlessui/react";

const ChainPicker = ({ selectedChain, onChange }) => {
	const chainOptions = Object.entries(chains).map(([id, name]) => ({
		id,
		name,
	}));

	// Set first option by default if no chain is selected
	useEffect(() => {
		if (!selectedChain && chainOptions.length > 0) {
			onChange(chainOptions[0].id);
		}
	}, [selectedChain, chainOptions, onChange]);

	const getSelectedChainName = () => {
		return (
			chainOptions.find((chain) => chain.id === selectedChain)?.name ||
			"Selecteer keten"
		);
	};

	return (
		<div className="">
			<Listbox value={selectedChain} onChange={onChange}>
				<div className="relative">
					<Listbox.Button className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<BuildingOfficeIcon className="w-5 h-5 text-gray-400" />
						{getSelectedChainName()}
						<ChevronDownIcon
							className="w-5 h-5 ml-1 -mr-1 text-gray-400"
							aria-hidden="true"
						/>
					</Listbox.Button>
					<Listbox.Options className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-1">
							{chainOptions.map((chain) => (
								<Listbox.Option
									key={chain.id}
									value={chain.id}
									className={({ active }) =>
										`${
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700"
										} group flex items-start w-full px-4 py-2 text-sm text-left`
									}
								>
									<div className="text-left font-semibold">
										{chain.name}
									</div>
								</Listbox.Option>
							))}
						</div>
					</Listbox.Options>
				</div>
			</Listbox>
		</div>
	);
};

export default ChainPicker;
