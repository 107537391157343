import React from "react";
import { usePortal } from "../context/PortalContext";
import Heading from "../../shared/components/UIElements/Heading";
import CheckList from "../../shared/components/UIElements/CheckList";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

const PatientSite = () => {
	const { portalSettings } = usePortal();
	const practiceName = portalSettings?.name || "Uw praktijk";

	const benefits = [
		"U krijgt een plekje op onze digitale Snelterecht lijst. Zo kunnen we u sneller helpen!",
		"U ontvangt per Whatsapp een bericht zodra er een plekje bij uw behandelaar is vrijgekomen voor u.",
		"Het staat u uiteraard vrij het vrijgekomen plekje (niet) te accepteren. Wij ontvangen daar automatisch bericht over.",
		"Uw privacy wordt gewaarborgd en u kunt zich op elk moment van de Snelterecht lijst afhalen.",
	];

	const faqs = [
		{
			question: "Hoe zit het met mijn privacy?",
			answer: "We bewaren uw gegevens niet langer dan strikt noodzakelijk. Op het moment dat u een afspraak bevestigt, of uw originele afspraak heeft plaatsgevonden, worden uw gegevens automatisch verwijderd uit het systeem van Snelterecht..",
		},
		{
			question:
				"Hoe kan ik mezelf weer van de Snelterecht lijst verwijderen?",
			answer: `In het eerste WhatsApp-bericht dat u van ons heeft ontvangen kunt u via de knop uzelf weer van de Snelterecht lijst verwijderen.

Let op: dit kan niet ongedaan gemaakt worden.

Uw gegevens worden dan in onze systemen geanonimiseerd. `,
		},
		{
			question: "Ik heb een afspraak, maar die is te ver weg...",
			answer: "Ook dan kunt u op de lijst van Snelterecht. Als er eerder een plek beschikbaar is, dan krijgt u bericht, maar uw reeds gemaakte afspraak blijft gewoon staan.",
		},
		{
			question:
				"Ik ben paar dagen weg, en daardoor kort niet beschikbaar.",
			answer: "Geen probleem! U krijgt een bevestiging dat u op Snelterecht staat en kunt daarbij opgeven dat u een periode niet beschikbaar bent. Voor de praktijk fijn om te weten dat we u dan niet hoeven te benaderen.",
		},
	];

	return (
		<div className="h-full flex flex-col max-w-6xl md:max-w-4xl mx-auto px-6 md:px-0">
			<div className="space-y-8 pt-12 pb-12">
				{/* Header Section */}
				<img
					src={portalSettings?.logoUrl}
					alt=""
					className="h-14 w-auto mb-20"
				/>
				<div className="border-b border-slate-200 pb-20">
					<div className="text-orange-500 font-bold text-xl">
						Zorg die niet wacht. Dat is Snelterecht.
					</div>
					<h1 className="text-3xl font-bold mt-2">
						{practiceName} maakt gebruik van Snelterecht
					</h1>
					<p className="text-gray-600 pt-3">
						U kunt, ondanks de wachtlijst, bij {practiceName} eerder
						een afspraak krijgen via Snelterecht.
					</p>
				</div>

				{/* Main Info Section */}
				<div className="pt-12">
					<Heading size="2">
						Bij {practiceName} kunt u eerder in de praktijk terecht
						via de Snelterecht lijst
					</Heading>
					<p className="text-gray-600 pt-3">
						Op deze manier kunt u, ondanks de wachttijd bij de
						praktijk, sneller terecht voor uw afspraak. Neem contact
						op met de praktijk om u toetevoegen aan de lijst van
						Snelterecht.
					</p>
				</div>

				<div className="mx-auto pb-12">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						{/* Benefits Section */}
						<div>
							<h4 className="uppercase text-slate-500 font-bold">
								Voordelen op een rij…
							</h4>
							<div className="mt-4">
								<CheckList items={benefits} />
							</div>
						</div>

						{/* WhatsApp Section */}
						<div className="bg-slate-50 p-10 rounded-3xl border border-slate-200">
							<h3 className="text-xl font-bold">
								We versturen u de uitnodigingen voor vrijgekomen
								plekken per WhatsApp
							</h3>
							<div className="mt-4 space-y-4">
								<p className="text-gray-600">
									Kunt u op het aangegeven tijdstip? Geef dit
									dan aan door op de knop te klikken.
								</p>
								<p className="text-gray-600">
									Kunt u niet? Geef dit dan ook alstublieft
									aan, dan ziet de praktijk dat u niet
									beschikbaar bent en kunnen ze ook andere
									patiënten benaderen.
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* FAQ Section */}
				<div className="pt-12 border-t border-slate-200">
					<Heading size="2">Veelgestelde vragen</Heading>
					<div className="mt-4 space-y-2">
						{faqs.map((faq, index) => (
							<Disclosure key={index}>
								{({ open }) => (
									<div className="bg-white rounded-lg border border-slate-200">
										<Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-3 text-left hover:bg-slate-50">
											<span className="font-medium">
												{faq.question}
											</span>
											<ChevronUpIcon
												className={`${
													open
														? "rotate-180 transform"
														: ""
												} h-5 w-5 text-gray-500`}
											/>
										</Disclosure.Button>
										<Disclosure.Panel className="px-4 pb-3 text-gray-600">
											{faq.answer}
										</Disclosure.Panel>
									</div>
								)}
							</Disclosure>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PatientSite;
