import React from "react";

const YellowNote = ({ children }) => {
	return (
		<div className="bg-yellow-100 p-5 shadow shadow-yellow-800/20">
			<h4 className="text-yellow-800 opacity-70 text-[13px] font-semibold uppercase mb-1">
				OPMERKING
			</h4>
			<p className="text-yellow-900 text-sm first-letter:uppercase">
				{children}
			</p>
		</div>
	);
};

export default YellowNote;
