import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { PhoneIcon, PencilSquareIcon } from "@heroicons/react/16/solid";
import useEventSource from "../../../shared/hooks/useEventSource";

import Tooltip from "../../../shared/components/UIElements/Tooltip";
import FormattedPhoneNumber from "../../../shared/components/util/FormattedPhoneNumber";
import SkeletonLoadingItem from "../../../shared/components/UIElements/SkeletonLoadingItem";

import ChoosePhoneNumber from "./ChoosePhoneNumber";
import PatientDataRating from "./PatientDataRating";

const hasPatientData = (patientData) => {
	return (
		patientData &&
		(patientData.firstName ||
			patientData.lastName ||
			patientData.patientnummer ||
			(patientData.phoneNumbers && patientData.phoneNumbers.length > 0))
	);
};

const PrefilledFromDesktopInfo = ({
	setStep,
	setPrefilledValues,
	setIsLoadingIntegrationResult,
	setFoundPhoneNumbers,
	isVisible,
}) => {
	const { values, setFieldValue } = useFormikContext();
	const location = useLocation();
	const [error, setError] = useState(null);
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [initialData, setInitialData] = useState(null);

	const queryParams = new URLSearchParams(location.search);
	const documentId = queryParams.get("id");
	const key = queryParams.get("key");

	const sseEndpoint =
		documentId && key
			? `/api/desktop/integration-results/${documentId}/stream?key=${key}`
			: null;

	const { data, error: sseError, isLoading } = useEventSource(sseEndpoint);

	useEffect(() => {
		setIsLoadingIntegrationResult(isLoading);
	}, [isLoading, setIsLoadingIntegrationResult]);

	useEffect(() => {
		if (sseError) {
			setError(
				"Er is een fout opgetreden bij het ophalen van de gegevens",
			);
			toast.error("Fout bij het ophalen van de patiëntgegevens", {
				position: "bottom-center",
			});
		}
	}, [sseError]);

	useEffect(() => {
		if (data?.patientData && hasPatientData(data.patientData)) {
			const initialValues = {
				...data.patientData,
				phoneNumber:
					data.patientData.phoneNumbers.length === 1
						? data.patientData.phoneNumbers[0].tel
						: null,
			};

			setInitialData(initialValues);
			setPrefilledValues(initialValues);
			setPhoneNumbers(data.patientData.phoneNumbers);
			setFoundPhoneNumbers(data.patientData.phoneNumbers);

			// Only set field values if they're empty
			Object.keys(initialValues).forEach((key) => {
				if (!values[key]) {
					setFieldValue(key, initialValues[key]);
				}
			});
		} else if (data && !hasPatientData(data.patientData)) {
			setError(
				"Geen patiëntdata gevonden. Weet je zeker dat de patiëntenkaart open stond?",
			);
		}
	}, [data, setPrefilledValues, setFoundPhoneNumbers, setFieldValue, values]);

	if (!isVisible) {
		return null;
	}

	if (isLoading) {
		return (
			<>
				<LoadingSkeleton />
			</>
		);
	}

	if (error) {
		return <ErrorMessage message={error} />;
	}

	if (!initialData) {
		return null;
	}

	return (
		<div className="mb-6">
			<PatientInfoCard
				initialData={initialData}
				values={values}
				phoneNumbers={phoneNumbers}
				setStep={setStep}
			/>

			{phoneNumbers.length > 1 && (
				<ChoosePhoneNumber
					phoneNumbers={phoneNumbers}
					onChange={(value) => setFieldValue("phoneNumber", value)}
				/>
			)}
			<div className="mt-3">
				<PatientDataRating documentId={documentId} accessKey={key} />
			</div>
		</div>
	);
};

const LoadingSkeleton = () => (
	<div className="mb-6">
		<div className="bg-slate-300 rounded-xl p-4 flex justify-between w-fit">
			<div className="pr-8 space-y-2">
				<SkeletonLoadingItem
					width="w-40"
					height="h-6"
					color="slate-500"
				/>
				<SkeletonLoadingItem
					width="w-16"
					height="h-4"
					color="slate-400"
				/>
				<p className="flex items-center">
					<PhoneIcon className="size-4 mr-2 text-slate-700" />
					<SkeletonLoadingItem
						width="w-20"
						height="h-4"
						color="slate-400"
					/>
				</p>
			</div>
		</div>
	</div>
);

const ErrorMessage = ({ message }) => (
	<div className="bg-orange-50 border-orange-100 border rounded-lg px-4 py-3 text-sm h-fit">
		<p className="text-orange-900">{message}</p>
	</div>
);

const PatientInfoCard = ({ initialData, values, phoneNumbers, setStep }) => (
	<div className="bg-slate-300 rounded-xl p-4 flex justify-between w-fit">
		<div className="pr-8">
			<p className="text-lg font-semibold">
				{values?.firstName || initialData?.firstName}{" "}
				{values?.lastName || initialData?.lastName}
			</p>
			<p className="text-sm text-slate-700">
				{values?.patientnummer || initialData?.patientnummer}
			</p>
			{phoneNumbers?.length === 1 && (
				<p className="flex items-center pt-2">
					<PhoneIcon className="size-4 mr-2 text-slate-700" />
					<FormattedPhoneNumber
						phoneNumber={
							values?.phoneNumber || initialData?.phoneNumber
						}
					/>
				</p>
			)}
		</div>
		<div>
			<Tooltip content="Gegevens wijzigen" id="edit-patient">
				<button onClick={() => setStep(1)}>
					<PencilSquareIcon className="size-4 mr-1 text-slate-500" />
				</button>
			</Tooltip>
		</div>
	</div>
);

export default PrefilledFromDesktopInfo;
