import React from "react";
import { CheckIcon } from "@heroicons/react/16/solid";
import classNames from "classnames";

const CheckList = ({ items, horizontal = false }) => {
	return (
		<div
			className={classNames({
				"space-y-2": !horizontal,
				"flex flex-wrap gap-x-3 space-y-0.5": horizontal,
			})}
		>
			{items.map((item, index) => (
				<div key={index} className="flex items-start gap-x-1">
					<CheckIcon className="h-5 w-5 flex-shrink-0 text-green-600 mt-0.5" />
					<span className="font-semibold text-green-600">{item}</span>
				</div>
			))}
		</div>
	);
};

export default CheckList;
