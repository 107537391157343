import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Table from "../../../shared/components/Tables/Table";
import { Link } from "react-router-dom";
import Heading from "../../../shared/components/UIElements/Heading";
import SubHeading from "../../../shared/components/UIElements/SubHeading";
import {
	BuildingOfficeIcon,
	CurrencyEuroIcon,
	ExclamationTriangleIcon,
	ClockIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";

const HighChurnRisks = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [highRiskPractices, setHighRiskPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [filter, setFilter] = useState("all"); // "all", "trial", "paid"

	useEffect(() => {
		const fetchStats = async () => {
			setIsLoading(true);
			setError(null);
			try {
				const data = await fetchData(
					`/api/admin/practices/high-churn-risk/${user.id}`,
				);
				setHighRiskPractices(data || []);
			} catch (err) {
				console.error("Error fetching high risk practices:", err);
				setError(
					"Failed to fetch high churn risk practices. Please try again.",
				);
			} finally {
				setIsLoading(false);
			}
		};
		fetchStats();
	}, [fetchData, user.id]);

	const filteredPractices = highRiskPractices.filter((practice) => {
		if (filter === "all") return true;
		if (filter === "trial") return practice.isInTrial;
		if (filter === "paid") return !practice.isInTrial;
		return true;
	});

	return (
		<>
			<Heading>High Churn Risk Practices</Heading>
			<div className="mt-10">
				<div className="sm:flex sm:items-center mb-6">
					<SubHeading icon={ExclamationTriangleIcon}>
						All High Risk Practices ({filteredPractices.length})
					</SubHeading>
					<div className="mt-4 sm:ml-auto sm:mt-0">
						<div className="flex rounded-md shadow-sm">
							<button
								type="button"
								onClick={() => setFilter("all")}
								className={classNames(
									"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
									{
										"bg-indigo-600 text-white hover:bg-indigo-500":
											filter === "all",
										"bg-white text-gray-900 hover:bg-gray-50":
											filter !== "all",
									},
								)}
							>
								All
							</button>
							<button
								type="button"
								onClick={() => setFilter("trial")}
								className={classNames(
									"relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
									{
										"bg-indigo-600 text-white hover:bg-indigo-500":
											filter === "trial",
										"bg-white text-gray-900 hover:bg-gray-50":
											filter !== "trial",
									},
								)}
							>
								In Trial
							</button>
							<button
								type="button"
								onClick={() => setFilter("paid")}
								className={classNames(
									"relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
									{
										"bg-indigo-600 text-white hover:bg-indigo-500":
											filter === "paid",
										"bg-white text-gray-900 hover:bg-gray-50":
											filter !== "paid",
									},
								)}
							>
								Paid Customers
							</button>
						</div>
					</div>
				</div>

				{error && (
					<div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
						{error}
					</div>
				)}

				{!isLoading && filteredPractices.length === 0 && !error && (
					<div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded mb-6">
						No high-risk practices found with the current filter.
					</div>
				)}

				<Table data={filteredPractices} isLoading={isLoading}>
					<th
						scope="col"
						className="pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900"
						render={(row) => {
							if (!row.practice) {
								return (
									<div className="text-red-500">
										Invalid practice data
									</div>
								);
							}

							return (
								<>
									<div
										className={classNames(
											"group flex items-center gap-2",
										)}
									>
										<p className="font-bold text-black">
											<Link
												to={`/admin/practice/?id=${row.practice._id}`}
												className="hover:underline"
											>
												{row.practice.name ||
													"Unnamed Practice"}
											</Link>
										</p>
										{row.isInTrial && (
											<span className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800 ring-1 ring-inset ring-orange-600/20">
												In proefperiode
											</span>
										)}
										{row.practice.chain && (
											<span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-800 ring-1 ring-inset ring-blue-600/20">
												{row.practice.chain}
											</span>
										)}
									</div>
									<p className="text-gray-500">
										{row.practice.creator?.firstName ||
											"Unknown"}{" "}
										{row.practice.creator?.lastName || ""}
									</p>
								</>
							);
						}}
					>
						Praktijk
					</th>

					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							!row.allTimeStats ||
							row.allTimeStats.slotsCreated === 0 ? (
								"—"
							) : (
								<div
									className={classNames({
										"text-green-800 font-semibold underline":
											row.allTimeStats.slotsCreated > 4,
									})}
								>
									<span>{row.allTimeStats.slotsClaimed}</span>
									/{row.allTimeStats.slotsCreated}{" "}
									<span className="text-gray-500">
										(
										{(
											(
												row.allTimeStats.fillRate || 0
											).toFixed(2) * 100
										).toFixed(0)}
										%)
									</span>
								</div>
							)
						}
					>
						Gevulde plekken
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							!row.allTimeStats ||
							row.allTimeStats.patientsAdded === 0
								? "—"
								: row.allTimeStats.patientsAdded
						}
					>
						Patiënten toegevoegd
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<div
								className={
									!row.recentStats ||
									row.recentStats.slotsCreated === 0
										? "text-red-600 font-semibold"
										: ""
								}
							>
								{row.recentStats?.slotsClaimed || 0}/
								{row.recentStats?.slotsCreated || 0}
							</div>
						)}
					>
						Plekken afgelopen 30 dagen
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<div
								className={
									!row.recentStats ||
									row.recentStats.patientsAdded === 0
										? "text-red-600 font-semibold"
										: ""
								}
							>
								{row.recentStats?.patientsAdded || 0}
							</div>
						)}
					>
						Recent patiënten toegevoegd
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.financeStats ? (
								<>
									<CurrencyEuroIcon className="inline-block w-4 h-4 mr-1" />
									{row.financeStats.allTimeSpend}
								</>
							) : (
								"N/A"
							)
						}
					>
						Totaal uitgegeven
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							row.financeStats &&
							row.financeStats.activeFor !== undefined ? (
								<>
									<div className="flex items-center gap-1">
										<ClockIcon className="w-4 h-4" />
										<span>
											{row.financeStats.activeFor} maanden
										</span>
									</div>
								</>
							) : (
								"N/A"
							)
						}
					>
						Actief sinds
					</th>
				</Table>
			</div>
		</>
	);
};

export default HighChurnRisks;
