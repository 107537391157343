import React from "react";
import Avatar from "boring-avatars";

const BehandelaarAvatar = ({ firstName, lastName, size = 40 }) => {
	const initials =
		firstName && lastName
			? firstName[0].toUpperCase() + lastName[0].toUpperCase()
			: firstName && firstName[0]
				? firstName[0].toUpperCase()
				: "--";

	const isSmallerSize = size < 25;

	return (
		<div
			style={{
				position: "relative",
				userSelect: "none",
				fontSize: isSmallerSize ? `${size / 3.5}px` : `${size / 4}px`,
			}}
		>
			<Avatar
				size={size}
				variant="marble"
				colors={["#CB4ADD", "#591A85"]}
				name={`${firstName} ${lastName}`}
				style={{
					userSelect: "none",
					width: `${size}px`,
					height: `${size}px`,
				}}
			/>
			<div
				className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white select-none ${isSmallerSize ? "font-bold" : "font-semibold"}`}
				style={{
					fontSize: isSmallerSize
						? `${size / 2.3}px`
						: `${size / 2.7}px`,
				}}
			>
				{initials}
			</div>
		</div>
	);
};

export default BehandelaarAvatar;
