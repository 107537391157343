import React from "react";
import classNames from "classnames";
import { UserCircleIcon } from "@heroicons/react/24/solid";

/**
 * A component that renders a WhatsApp-style chat bubble
 *
 * @param {Object} props
 * @param {string} props.message - The message text to display
 * @param {boolean} props.isOutgoing - Whether the message is outgoing (sent by the user) or incoming
 * @param {string} props.timestamp - Optional timestamp to display
 * @param {React.ReactNode} props.children - Optional children to render inside the bubble
 * @param {string} props.className - Optional additional classes
 * @param {string} props.profilePicture - Optional URL for profile picture
 * @param {string} props.profileName - Optional name to display with profile picture
 */

const ChatBubble = ({
	message,
	isOutgoing = false,
	timestamp,
	children,
	className,
	profilePicture,
	profileName,
}) => {
	return (
		<div className="flex items-end mb-2">
			{!isOutgoing && profilePicture && (
				<div className="mr-2 flex-shrink-0 size-8 object-cover bg-white border border-slate-200 p-2 shadow-sm rounded-full">
					{profilePicture ? (
						<img
							src={profilePicture}
							alt={profileName || "Profile"}
							className=""
						/>
					) : (
						<UserCircleIcon className="w-8 h-8 text-gray-400" />
					)}
				</div>
			)}

			<div
				className={classNames(
					"max-w-[80%] rounded-2xl py-3 px-4 shadow-sm",
					isOutgoing
						? "bg-green-100 ml-auto rounded-br-none"
						: "bg-white mr-auto rounded-bl-none border border-gray-200",
					className,
				)}
			>
				{profileName && !isOutgoing && (
					<p className="text-xs font-medium text-gray-700 mb-1">
						{profileName}
					</p>
				)}

				{children && (
					<p className="text-sm text-gray-800 whitespace-pre-line">
						{children}
					</p>
				)}

				{timestamp && (
					<div className="flex justify-end mt-1">
						<span className="text-xs text-gray-500">
							{timestamp}
						</span>
					</div>
				)}
			</div>

			{isOutgoing && profilePicture && (
				<div className="ml-2 flex-shrink-0">
					{profilePicture ? (
						<img
							src={profilePicture}
							alt={profileName || "Profile"}
							className="w-8 h-8 rounded-full object-cover"
						/>
					) : (
						<UserCircleIcon className="w-8 h-8 text-gray-400" />
					)}
				</div>
			)}
		</div>
	);
};

export default ChatBubble;
