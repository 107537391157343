import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useUser } from "@clerk/clerk-react";
import {
	MagnifyingGlassIcon,
	PhoneIcon,
	IdentificationIcon,
	ExclamationTriangleIcon,
	CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";

import PrefilledFromDesktopInfo from "../components/creation-wizard/PrefilledFromDesktopInfo";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import useFetch from "../../shared/hooks/useFetch";
import Container from "../../shared/components/UIElements/Container";
import Button from "../../shared/components/FormElements/Button";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";

const SearchPatient = () => {
	const { user } = useUser();
	const location = useLocation();
	const [step, setStep] = useState(0);
	const [isLoadingIntegrationResult, setIsLoadingIntegrationResult] =
		useState(false);
	const [prefilledValues, setPrefilledValues] = useState(null);
	const [foundPhoneNumbers, setFoundPhoneNumbers] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [searchError, setSearchError] = useState(null);
	const [debugInfo, setDebugInfo] = useState({
		hasQueryParams: false,
		documentId: null,
		key: null,
	});

	const fetchData = useFetch();
	const [isFetching, setIsFetching] = useState(false);

	// Check if URL has required parameters
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const documentId = queryParams.get("id");
		const key = queryParams.get("key");

		setDebugInfo({
			hasQueryParams: !!(documentId && key),
			documentId,
			key,
		});

		// If no query params, don't show loading state
		if (!documentId || !key) {
			setIsLoadingIntegrationResult(false);
		}
	}, [location.search]);

	// Automatically search when prefilledValues are available
	useEffect(() => {
		if (
			prefilledValues &&
			(prefilledValues.patientnummer || prefilledValues.phoneNumber)
		) {
			handleSearch();
		}
	}, [prefilledValues]);

	// Initial form values
	const initialValues = {
		firstName: "",
		lastName: "",
		patientnummer: "",
		phoneNumber: "",
	};

	// Handle search using the /search endpoint
	const handleSearch = async () => {
		if (!prefilledValues) return;

		setIsSearching(true);
		setSearchError(null);

		try {
			// Build query parameters
			const queryParams = new URLSearchParams();

			if (prefilledValues.patientnummer) {
				queryParams.append(
					"patientNumber",
					prefilledValues.patientnummer,
				);
			}

			if (prefilledValues.phoneNumber) {
				queryParams.append("phoneNumber", prefilledValues.phoneNumber);
			} else if (
				prefilledValues.phoneNumbers &&
				prefilledValues.phoneNumbers.length > 0
			) {
				// Use the first phone number if phoneNumber is not set but phoneNumbers array exists
				queryParams.append(
					"phoneNumber",
					prefilledValues.phoneNumbers[0].tel ||
						prefilledValues.phoneNumbers[0],
				);
			}

			// Only proceed if we have at least one search parameter
			if (queryParams.toString()) {
				const response = await fetchData(
					`/api/patients/search?${queryParams.toString()}&creatorId=${user.id}`,
				);

				if (response.patient) {
					// Single patient found
					setSearchResults({
						type: "single",
						data: response.patient,
					});
				} else if (response.patients) {
					// Multiple patients found
					setSearchResults({
						type: "multiple",
						data: response.patients,
					});
				}
			} else {
				setSearchError(
					"Geen zoekgegevens beschikbaar. Zorg ervoor dat er een patiëntnummer of telefoonnummer is ingevuld.",
				);
			}
		} catch (error) {
			console.error("Search error:", error);
			if (error.status === 404) {
				setSearchError("Geen patiënt gevonden met deze gegevens.");
			} else {
				setSearchError(
					"Er is een fout opgetreden bij het zoeken. Probeer het later opnieuw.",
				);
			}
		} finally {
			setIsSearching(false);
		}
	};

	// Handle form submission
	const handleSubmit = (values) => {
		setPrefilledValues(values);
		handleSearch();
	};

	return (
		<Container>
			{/* Always render the Formik form with PrefilledFromDesktopInfo */}
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{(formikProps) => (
					<Form>
						<div style={{ display: "block" }}>
							<PrefilledFromDesktopInfo
								setStep={setStep}
								setPrefilledValues={setPrefilledValues}
								setIsLoadingIntegrationResult={
									setIsLoadingIntegrationResult
								}
								setFoundPhoneNumbers={setFoundPhoneNumbers}
								isVisible={true}
							/>
						</div>
						<button type="submit" className="hidden">
							Submit
						</button>
					</Form>
				)}
			</Formik>

			{/* Render loading screen when searching or loading integration results */}
			{isSearching || isLoadingIntegrationResult || isFetching ? (
				<div className="flex flex-col items-center justify-center min-h-[60vh]">
					<LoadingSpinner fullHeight={false} />
					<p className="mt-4 text-gray-600">
						{isLoadingIntegrationResult
							? "Patiëntgegevens ophalen..."
							: isSearching || isFetching
								? "Patiënt zoeken in het systeem..."
								: "Bezig..."}
					</p>
				</div>
			) : (
				<div className="container mx-auto py-8">
					{/* <h1 className="text-2xl font-bold mb-6">Patiënt zoeken</h1> */}

					{!prefilledValues && (
						<div className="bg-white rounded-lg shadow-md p-6 max-w-2xl">
							<div className="flex items-center mb-4">
								<MagnifyingGlassIcon className="h-6 w-6 text-gray-500 mr-2" />
								<h2 className="text-xl font-semibold">
									Geen patiëntgegevens gevonden
								</h2>
							</div>

							<p className="text-gray-600">
								Er zijn geen patiëntgegevens gevonden in het
								externe systeem. Controleer of de patiëntenkaart
								open staat in het desktop systeem.
							</p>
						</div>
					)}

					{/* Search Results */}
					{searchResults && (
						<div className="max-w-2xl mt-6">
							<div className="flex items-center mb-4">
								<MagnifyingGlassIcon className="h-6 w-6 text-brand-500 mr-2" />
								<h2 className="text-xl font-semibold">
									Zoekresultaten
								</h2>
							</div>

							{searchResults.type === "single" ? (
								<div>
									<div className="bg-green-50 px-4 py-3 rounded-xl border border-green-600 border-opacity-40 mb-6 flex items-center">
										<CheckCircleIcon className="size-4 text-green-700 mr-2" />
										<p className="text-sm text-green-700 font-medium">
											Patiënt gevonden op de wachtlijst!
										</p>
									</div>

									<div className="grid grid-cols-2 gap-4">
										<div>
											<p className="text-sm text-gray-500">
												Naam
											</p>
											<p className="font-medium">
												{searchResults.data.firstName}{" "}
												{searchResults.data.lastName}
											</p>
										</div>
										{searchResults.data.patientNumber && (
											<div>
												<p className="text-sm text-gray-500">
													Patiëntnummer
												</p>
												<p className="font-medium">
													{
														searchResults.data
															.patientNumber
													}
												</p>
											</div>
										)}
										{searchResults.data.phoneNumbers &&
											searchResults.data.phoneNumbers
												.length > 0 && (
												<div>
													<p className="text-sm text-gray-500">
														Telefoonnummer
													</p>
													<p className="font-medium">
														{
															searchResults.data
																.phoneNumbers[0]
														}
													</p>
												</div>
											)}
										<div>
											<p className="text-sm text-gray-500">
												Status
											</p>
											<p className="font-medium">
												{searchResults.data.status ===
												"waiting"
													? "Op wachtlijst"
													: searchResults.data.status}
											</p>
										</div>
									</div>

									<div className="mt-6">
										<Button
											href={`/patienten/${searchResults.data._id}`}
											iconSuffix={ArrowUpRightIcon}
										>
											Bekijk patiënt
										</Button>
									</div>
								</div>
							) : (
								<div>
									<div className="bg-blue-50 p-4 rounded-lg mb-6">
										<p className="text-sm text-blue-700">
											Er zijn {searchResults.data.length}{" "}
											patiënten gevonden met deze
											gegevens.
										</p>
									</div>

									<div className="space-y-4">
										{searchResults.data.map((patient) => (
											<div
												key={patient._id}
												className="border border-gray-200 rounded-lg p-4"
											>
												<div className="grid grid-cols-2 gap-4">
													<div>
														<p className="text-sm text-gray-500">
															Naam
														</p>
														<p className="font-medium">
															{patient.firstName}{" "}
															{patient.lastName}
														</p>
													</div>
													{patient.patientNumber && (
														<div>
															<p className="text-sm text-gray-500">
																Patiëntnummer
															</p>
															<p className="font-medium">
																{
																	patient.patientNumber
																}
															</p>
														</div>
													)}
													{patient.phoneNumbers &&
														patient.phoneNumbers
															.length > 0 && (
															<div>
																<p className="text-sm text-gray-500">
																	Telefoonnummer
																</p>
																<p className="font-medium">
																	{
																		patient
																			.phoneNumbers[0]
																	}
																</p>
															</div>
														)}
													<div>
														<p className="text-sm text-gray-500">
															Status
														</p>
														<p className="font-medium">
															{patient.status ===
															"waiting"
																? "Op wachtlijst"
																: patient.status}
														</p>
													</div>
												</div>

												<div className="mt-4">
													<Button
														href={`/patienten/${patient._id}`}
													>
														Bekijk patiënt
													</Button>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					)}

					{/* Search Error */}
					{searchError && !searchResults && (
						<div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mt-6">
							<div className="flex items-center mb-4">
								<ExclamationTriangleIcon className="h-6 w-6 text-orange-500 mr-2" />
								<h2 className="text-xl font-semibold">
									Geen resultaten
								</h2>
							</div>

							<div className="bg-orange-50 p-4 rounded-lg">
								<p className="text-sm text-orange-700">
									{searchError}
								</p>
							</div>
						</div>
					)}
				</div>
			)}
		</Container>
	);
};

export default SearchPatient;
